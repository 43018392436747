<template>
	<div class="container" style="padding-bottom: 50px;" v-loading='loading'>
		<div class="top" style="display:flex;justify-content: space-between;">
			<span>顾问问候语</span>
		</div>
		<el-form :model="ruleForm" class="content" ref="ruleForm" label-width="180px">
			<div style="margin-bottom: 15px;color: #999;font-size: 14px;padding-left: 20px;width: 800px;line-height: 23px;">
				温馨提示:问候语回复支持占位符员工姓名 {{left}}staff_name{{right}}及客户昵称{{left}}nick_name{{right}}。
				例如：{{left}}nick_name{{right}}你好，专属顾问{{left}}staff_name{{right}}负责接待您，系统回复时将是：小草莓（具体名字由该客户的微信昵称决定）你好，专属顾问张三负责接待您 (具体名字由该客服的昵称决定）
			</div>
			<el-form-item label="未添加企业微信问候语:">
				<div style="display: flex;">
					<div style="position: relative;">
						<el-input @input="descInput" type="textarea" :rows="6" resize="none" style="width: 800px;" v-model="ruleForm.nopeContent"
						 maxlength="200"></el-input>
						<span style='position:absolute;bottom:0px;right:20px;'>{{txtVal}}/200</span>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="已添加企业微信问候语:">
				<div style="display: flex;">
					<div style="position: relative;">
						<el-input @input="descInput1" type="textarea" :rows="6" resize="none" style="width: 800px;" v-model="ruleForm.yesContent"
						 maxlength="200"></el-input>
						<span style='position:absolute;bottom:0px;right:20px;'>{{txtVal1}}/200</span>
					</div>
				</div>
			</el-form-item>
		</el-form>

		<div class="footer">
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {greetingInfoget,greetingInfoEdit} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				ruleForm: {
					yesContent:'Hi，我是张三，是你的专属顾问，请问有什么是我可以帮到你的吗？如果我没有及时回复你的消息，你可以打开微信—进入通讯录—企业微信联系人列表，找到我然后给我留言哦~',
					nopeContent: 'Hi，我是张三，是你的专属顾问，请问有什么是我可以帮到你的吗？如果我没有及时回复你的消息，你可以扫码下方二维码添加我的企业微信，给我留言哦~'
				},
				txtVal: 0,
				loading:false,
				txtVal1:0,
				left:'{{',
				right:'}}'
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async getData(){
				try{
					this.loading = true
					let result = await greetingInfoget()
					this.ruleForm.yesContent = result.Result.GreetingAddedEnterpriseWx
					this.ruleForm.nopeContent = result.Result.GreetingNotAddedEnterpriseWx
					this.txtVal = this.ruleForm.nopeContent.length
					this.txtVal1 =  this.ruleForm.yesContent.length
					// console.log(result,'初始化')
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			descInput() {
				this.txtVal = this.ruleForm.nopeContent.length;
			},
			descInput1() {
				this.txtVal1 = this.ruleForm.yesContent.length;
			},
			async saveSupiler(){
				try{
					this.loading = true
					let data = {
						GreetingNotAddedEnterpriseWx : this.ruleForm.nopeContent,
						GreetingAddedEnterpriseWx : this.ruleForm.yesContent
					}
					let result = await greetingInfoEdit(data)
					if(result.IsSuccess){
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getData()
					}
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		background-color: #fff;

		.top {
			line-height: 40px;
			border-bottom: 1px solid #ddd;
			padding: 10px;
		}

		.content {
			padding: 20px 0;
		}

		.footer {
			line-height: 80px;
			background-color: #fff;
			position: fixed;
			bottom: 0px;
			width: 100%;
			text-align: center;
		}
	}
</style>
